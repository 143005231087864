import React from 'react';
import cx from 'classnames';
import Link from 'next/link';

import styles from './PublicFooter.module.scss';

interface PublicFooterProps {
  className?: string;
}
const PublicFooter: React.FC<PublicFooterProps> = ({className}) => {
  const currentYear = new Date().getFullYear();
  return (<div className={cx(className)}>
    <div className={cx(styles.endMatter, 'mb-4')}>
      <div className="flex items-center">
        <img src="/homepage/logo_star.png" className="w-12 mr-3" alt="Dreamgifts" title="Dreamgifts" />
        <p className="font-medium">©{currentYear}</p>
      </div>
      <div className="flex items-center">
        <a href="https://twitter.com/getdreamgifts" target="_blank" rel="noopener noreferrer" className="mr-5">
          <img src="/homepage/twitter_logo.svg" />
        </a>
        <a href="https://instagram.com/getdreamgifts" target="_blank" rel="noopener noreferrer">
          <img src="/homepage/instagram_logo.svg" />
        </a>
      </div>
    </div>
    <div className={styles.endMatter2}>
      <div className={styles.termsLinks}>
        <a href="/terms" target="_blank">Terms</a>
        <a href="/privacy" target="_blank">Privacy</a>
        <a href="/login">Login</a>
        <a href="/brand-sponsorship">Brand Sponsorship</a>
      </div>
      <a href="mailto:support@dreamgifts.co">support@dreamgifts.co</a>
    </div>
  </div>);
};

export default PublicFooter;